import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "./index.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import SimpleNav from "../components/simple-nav";
import imgHero from "../images/gfx/hero-3.jpg"
import imgMobileHero from "../images/gfx/mobile-hero-3.jpg"

import Hero from "../components/hero-standard";
import FooterMin from "../components/footer-min";

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Help" />
        <SimpleNav />
        <div>
          <Hero h1="Help" h2="Using your ticket" desktopImage={imgHero} mobileImage={imgMobileHero} />
          <div className="article">
            <div className='article-inner'>
              <h2>How to share your mobile ticket</h2>
              <p>You will have received your private ticket link by email on behalf of your transport operator. If the passenger is someone other than yourself (e.g. your child), the private ticket link needs to be shared with the designated passenger in order for them to access it on their mobile device.</p>
              <p>The simplest way to do that is to forward the email onto the email address of the designated passenger.</p>
              <p>Alternatively, on your own mobile device you can also open the private ticket link and share the page with the passenger (SMS, WhatsApp, Messenger).</p>

              <h2>How to use your mobile ticket</h2>
              <p>Passengers receiving the private ticket link will either want to bookmark the page in their mobile web browser or screenshot the ticket for easy offline access.</p>
              <p>When using a mobile ticket, the passenger must ensure their screen brightness is turned up and they have enough battery or data allowance to open the ticket.</p>
              <p>Transport operators reserve the right to refuse travel in the event the passenger is unable to present a valid ticket.</p>

              <h2>How to scan your ticket</h2>
              <p>Point the ticket barcode in the direction of the camera lens of the scanning device (located directly above the scanner screen).</p>
              <p>The visual guide on the scanner screen will hint where the barcode needs to be positioned.</p>
              <p>The scanner will then check the validity of your ticket allowing you to board the vehicle.</p>

              <h2>Unauthorised ticket sharing</h2>
              <p>To ensure fairness to passengers, ticket buyers and transport operators, tickets are validated against a database to flag fraudulent or duplicated tickets.</p>
              <p><strong>You must not share the private ticket link with anyone apart from the designated passenger.</strong></p>
              <p>In the event a duplicate pass is found, the operator reserves the right to void the ticket without refund.</p>
              <p>If you suspect your ticket has been compromised, you must inform the transport operator immediately.</p>

              <h2>Further help</h2>
              <p><strong>Any query regarding the below items need to go directly to your transport provider</strong>. This is typically your bus/coach company but could be your school/college - whichever organisation is responsible for organising your travel and issuing tickets.</p>
              <div className="two-col">
                <ul>
                  <li>Timetable</li>
                  <li>Route/bus stops</li>
                  <li>Ticket price</li>
                  <li>Ticket options</li>
                  <li>Ticket availability</li>
                </ul>
                <ul>
                  <li>Payments</li>
                  <li>Renewals</li>
                  <li>Discount/bursaries</li>
                  <li>Refunds/cancellations</li>
                  <li>Lost property</li>
                </ul>
              </div>
              <p>Contacting ShuttleID regarding the above items will delay in your query being resolved as ultimately it can only be handled by your transport provider.</p>
              <p>For any other technical issues with regards to the use of your ticket, please email <a href="mailto:support@shuttleid.uk">support@shuttleid.uk</a>. Our support team will endeavour to respond to your request within 24 hours.</p>
            </div>
          </div>
        </div>
        <FooterMin />
      </Layout>
    );
  }
};

export default BlogPage
