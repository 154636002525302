import React from "react"
import imgLogo from "../images/gfx/logo.png"
import "./nav.css";

const SimpleNav = () => (
  <>
    <div className="nav">
      <div className="nav__main">
        <div className="nav__logo">
          <img className="nav__logo-img" src={imgLogo} />
        </div>
      </div>
    </div>
  </>
);

export default SimpleNav
