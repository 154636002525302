import React from "react"
import "./footer.css";

const FooterMin = () => (
  <footer>
    <div className="footer-parent">
      <div className="footer-text">
        <p>© {new Date().getFullYear()} ShuttleID. All rights reserved.</p>
        <p>Cevenco Ltd trading as ShuttleID is a limited company registered in England and Wales.</p>
        <p>Registered number: 11901852. Registered office: 233 Main Street, Leeds, LS17 8LA.</p>
      </div>
    </div>
  </footer>
);

export default FooterMin